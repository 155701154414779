jQuery(document).ready(function($) {
	const header = document.getElementById('masthead');
	const headerHeight = header.offsetHeight;

	setTimeout(() => {
		document.documentElement.classList.add('loaded');
	}, 500);
	
	function toggleScrollClass() {
		if (window.scrollY > 10) {
			document.documentElement.classList.add('scrolled');
			header.classList.add('shadow-sm');
		} else if (window.scrollY < 5) {
			document.documentElement.classList.remove('scrolled');
			header.classList.remove('shadow-sm');
		}			
	}
	
	toggleScrollClass();
	window.onscroll = (() => {
		toggleScrollClass();
	});

	// shrink mobile header when navbar toggled
	const mainNav = document.getElementById('menu-main-nav-container');
	if (mainNav != null) {
		$(mainNav).on('show.bs.collapse', function() {
			document.documentElement.classList.add('mobile-nav-open');
		});
		$(mainNav).on('hide.bs.collapse', function() {
			document.documentElement.classList.remove('mobile-nav-open');
		});
	}

	// allow UCI header links hover colour to be random
	const setHoverColourRandomly = () => {
		const colours = ['pink', 'blue', 'yellow'];
		let randomColour = colours[Math.floor(Math.random()*colours.length)];
		document.body.style.setProperty('--hover-colour', 'var(--' + randomColour + ')');
	};

	const headerLinks = header.querySelectorAll('a');
	if (headerLinks.length > 0) {
		headerLinks.forEach((headerLink) => {
			headerLink.addEventListener('mouseenter', (e) => {
				setHoverColourRandomly();
			});
		});
	}
	const formInputs = document.querySelectorAll('form input:not(.btn)');
	if (formInputs.length > 0) {
		formInputs.forEach((input) => {
			input.addEventListener('mouseenter', (e) => {
				setHoverColourRandomly();
			});
		});
	}
	const hoverColourChangers = document.querySelectorAll('a.hover-colour-change');
	if (hoverColourChangers.length > 0) {
		hoverColourChangers.forEach((colourChanger) => {
			colourChanger.addEventListener('mouseenter', (e) => {
				setHoverColourRandomly();
			});
		});
	}

	// COLLAPSE
	const collapses = document.querySelectorAll('[data-toggle="collapse"]');

	if (collapses.length) {
		
		collapses.forEach((collapse) => {
			let target = document.getElementById(collapse.dataset.target.replace('#', ''));

			if (target != null) {
				$(target).on('show.bs.collapse', function() {
					collapse.classList.add('collapse-open');
				});
				$(target).on('hide.bs.collapse', function() {
					collapse.classList.remove('collapse-open');
				});
			}

		});
	}


});